import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames/bind';
import {useSelector} from "react-redux";

// Selectors
import {isAstronautTheme, isMovieBorderTheme} from "../../../state/modules/city/selectors";

// Components
import Button from '../../Button';

// Router
import { paths, getRoute } from '../../../entry/routes';

// Icons

// Assets
import cover from '../assets/cover.png';
import comet from "../../../assets/Astronaut_images/comet_large.png";
import Heart from '../../../icons/vk/pinkHeart.svg';
import VKLogo from '../../../icons/vk/vkLogo.svg';
import Match from '../../../icons/vk/match.svg';

// Styles
import styles from './HalvedCard.styl';
import PromotionMovieBorderDecor from "../../PromotionMovieBorder/PromotionMovieBorderDecor";

const cx = classNames.bind(styles);

export default function HalvesCard(props) {
  const { className, name } = props;
  const isAstronaut = useSelector(isAstronautTheme);
  const isMovieBorder = useSelector(isMovieBorderTheme);

  const intl = useIntl();

  return (
    <div className={cx('HalvesCard', className)} data-test-id='halves_card'>
      <VKLogo style={{ width: '155px', height: '30px', flexShrink: '0' }} className={cx('HalvesCard__vk')}/>

      <div className={cx('HalvesCard__container')}>

        {isAstronaut && <img src={comet} width={148} height={106} alt={'decor'} className={cx('HalvesCard__cosmo_decor')}/>}
        <div className={cx('HalvesCard__imageContainer')}>
          <LazyLoadImage className={cx('HalvesCard__picture')} alt={name} src={cover}/>
          <Match style={{ width: '260px', height: '130px', flexShrink: '0' }} className={cx('HalvesCard__imageContainer_match')}/>
          {isMovieBorder && <PromotionMovieBorderDecor srcView={'4'} className={cx('HalvesCard__PromoBorder')}/>}

        </div>

        <span className={cx('HalvesCard__heading')}>
          {name}
        </span>
        <div className={cx('HalvesCard__note')}>{intl.formatMessage({id: 'halves.slogan'})}</div>

        <Button className={cx('HalvesCard__button')} to={getRoute(paths.halves)} secondary>
          {intl.formatMessage({id: 'button.choosePizza'})}
        </Button>
      </div>
      <Heart style={{ width: '163px', height: '163px', flexShrink: '0' }} className={cx('HalvesCard__heart')}/>

    </div>
  );
}

HalvesCard.defaultProps = {
  className: '',
  name: '',
};

HalvesCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
};
