import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

// assets
import heart from '../../../../components/CreatePizzaCard/assets/create_heart.png';

// Components
import Heading from '../../../../components/Heading';
import CreatePizzaCard from '../../../../components/CreatePizzaCard/DesktopView';
import HalvesCard from '../../../../components/HalvesCard/DesktopView';
import ProductCard from '../../../../components/ProductCard';
import ProductCardHatikoBanner from '../../../../components/PromotionHatico/ProductCardHatikoBanner/ProductCardHatikoBanner';
import PromotionMovieDecor from '../../../../components/PromotionMovie10/PromotionMovieDecor';

// Hooks
import { useHatiko } from '../../../../components/PromotionHatico/useHatiko';

// Partials
import useProductList from '../useProductList';
import PizzaFilter from '../../PizzaFilter/DesktopView';

//Actions
import { changeBonuses } from '../../../../state/modules/user/actions';

//Utils
import { filterWillBurnPoints, useBonuses } from '../../../../utils/useBonuses';

// Styles
import styles from './ProductList.styl';
import { customScrollTo } from '../../../../utils/customScrollTo';
import PawGroup from '../../../../components/PromotionHatico/PawGroup/PawGroup';
import { promoGameProps } from '../../../../state/modules/promotionGame/selectors';
import PromoArtefact from '../../PromoGame/PromoArtefact';
import { toggleHomeCatalog } from '../../../../state/modules/ui/actions';
import FootballCategoryDecor from '../../../../components/PromotionFootball/components/FootballCategoryDecor/FootballCategoryDecor';
import { isFootBallTheme, isMovie10Theme } from '../../../../state/modules/city/selectors';
import { selectFootballGoodsIds } from '../../../../state/modules/catalog/selectors';

const cx = classNames.bind(styles);

export default function ProductList(props) {
  const isAuthenticated = useSelector(state => {
    return state.user?.isAuthenticated;
  });
  const { isHatiko, isHaticoGood } = useHatiko();
  const isFootballActive = useSelector(isFootBallTheme);
  const isMovieActive = useSelector(isMovie10Theme);
  const footballGoodsIds = useSelector(selectFootballGoodsIds);

  const { artefactsIds, artefacts, isPromotionGameActive } = useSelector(promoGameProps);
  const compositionCart = useSelector(state => state.cart?.composition);
  const history = useSelector(state => state.user?.profile?.loyalty?.history);
  const points = useSelector(state => state.user?.profile?.loyalty?.points);
  const { className, category, setIsOpenModalBonus, setIsOpenModalAuth, isHiddenActions } = props;
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const productListRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      const { hash: categoryUrl } = window.location;
      const slicedCategoryUrl = categoryUrl.substring(1);
      const handleScroll = () => {
        elementRef.current = document.querySelector(`#${slicedCategoryUrl}`);
        if (!elementRef.current) {
          return;
        }
        dispatch(toggleHomeCatalog(false));
        customScrollTo(elementRef.current, { block: 'start' });
        dispatch(toggleHomeCatalog(true));
      };
      handleScroll();
    }, 300);
  }, []);

  useEffect(() => {
    if (isAuthenticated && history && points) {
      const { willBurn } = filterWillBurnPoints(history, points);
      const bonuses = useBonuses(points, compositionCart, willBurn);
      dispatch(changeBonuses(bonuses));
    }
  }, [compositionCart, points, history]);

  const {
    goods,
    filteredStateGood,
    getGoodsWithoutDublicates,
    filteredGoods,
    filteredCategory,
    removeIngredientAction,
    cancelRemovedIngredientsAction,
    addToCart,
    sailplayGifts,
    sorting,
    switchSorting,
  } = useProductList(category);
  useEffect(() => {
    getGoodsWithoutDublicates();
  }, [goods]);

  const renderCard = (good, category, index) => {
    const isConstructor = good.alias.startsWith('sozda');

    if (isConstructor) {
      return isHiddenActions ? null : (
        <>
          <CreatePizzaCard
            key={good.id}
            className={cx('ProductList__item_constructor')}
            name={good.name}
            id={good.id}
            category={category}
          />
          {isMovieActive && (
            <PromotionMovieDecor
              srcView={'3'}
              className={cx('ProductList__MovieDecor_constructor')}
            />
          )}
        </>
      );
    }

    switch (good.alias) {
      case 'serdce-konstruktor-1707098361': {
        return isHiddenActions ? null : (
          <>
            <CreatePizzaCard
              key={good.id}
              className={cx('ProductList__item_constructor')}
              name={good.name}
              id={good.id}
              category={category}
              imgSrc={heart}
            />
          </>
        );
      }
      case 'halves': {
        return isHiddenActions ? null : (
          <>
            <HalvesCard
              key={good.id}
              className={cx('ProductList__item_halves')}
              name={good.name}
              category={category}
            />
            {isMovieActive && (
              <PromotionMovieDecor srcView={'1'} className={cx('ProductList__MovieDecor_halves')} />
            )}
          </>
        );
      }
      default: {
        return (
          <ProductCard
            key={good.id}
            cardPositionInList={index}
            sailplayGifts={sailplayGifts}
            className={cx(
              footballGoodsIds?.includes(good.id) && 'ProductList__item_Football_promo',
            )}
            good={good}
            removeIngredientAction={removeIngredientAction}
            cancelRemovedIngredientsAction={cancelRemovedIngredientsAction}
            addToCart={addToCart}
            isFromProductList
            setIsOpenModalBonus={setIsOpenModalBonus}
            setIsOpenModalAuth={setIsOpenModalAuth}
            category={category}
            isHiddenActions={isHiddenActions}
            additionalBanner={isHatiko && isHaticoGood(good.id) && <ProductCardHatikoBanner />}
            promotionArtefactProperties={
              artefactsIds.includes(good.id) && artefacts.find(art => art.id === good.id).imageProps
            }
          />
        );
      }
    }
  };

  return (
    <section className={cx('ProductList', className)} ref={productListRef}>
      <PizzaFilter
        name={category.filter(item => item.alias === filteredCategory[0])[0]?.name}
        className={cx('ProductList__section')}
        changeFilterPizza={filteredGoods}
        goods={goods}
        id={filteredCategory[0]}
        sorting={sorting}
        switchSorting={switchSorting}
      />
      {category.map(item => {
        return (
          filteredCategory.includes(item.alias) && (
            <section
              id={`${item.alias}`}
              style={{ position: isHatiko || isFootballActive ? 'relative' : 'static' }}
            >
              {item.alias !== filteredCategory[0] && (
                <Heading className={cx('ProductList__heading')} level={2} tagName="h4">
                  {item.name}
                </Heading>
              )}
              {isPromotionGameActive && artefactsIds.includes(item.alias) && (
                <PromoArtefact
                  id={item.alias}
                  activeEffect={'toTop'}
                  className={cx('ProductList__PromoArtefact')}
                />
              )}
              {isHatiko && item.alias === 'combo' && (
                <PawGroup classname={'ProductList__HatikoPaw'} />
              )}
              {isFootballActive && item.alias === 'combo' && (
                <FootballCategoryDecor className={cx('ProductList__FootballDecor')} />
              )}
              <div className={cx('ProductList__list')}>
                {filteredStateGood?.map((elem, index) => {
                  if (elem.category === item.alias) {
                    return (
                      <div className={cx('ProductList__item')}>
                        {renderCard(elem, item.name, index)}
                      </div>
                    );
                  }
                })}
              </div>
            </section>
          )
        );
      })}
      {isMovieActive && (
        <PromotionMovieDecor srcView={'5'} className={cx('ProductList__MovieDecor_filter')} />
      )}
    </section>
  );
}

ProductList.defaultProps = {
  className: '',
  isHiddenActions: false,
};

ProductList.propTypes = {
  className: PropTypes.string,
  category: PropTypes.object.isRequired,
  isHiddenActions: PropTypes.bool,
};
