import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// Router
import { paths, getRoute } from '../../../entry/routes';

// Components
import Button from '../../Button';

// Icons
import cover from '../assets/cover.png';

// Styles
import styles from './CreatePizzaCard.styl';
import {useSelector} from "react-redux";
import {isMovieBorderTheme} from "../../../state/modules/city/selectors";
import PromotionMovieBorderDecor from "../../PromotionMovieBorder/PromotionMovieBorderDecor";


const cx = classNames.bind(styles);

export default function CreatePizzaCard(props) {
  const { className, id, name, imgSrc } = props;

  const intl = useIntl();
  const isMovieBorder = useSelector(isMovieBorderTheme);

  return (
    <div className={cx('CreatePizzaCard', className)} data-test-id="create_pizza_card">
      <div className={cx('CreatePizzaCard__container')}>
        <div style={{position: 'relative'}}>
          <LazyLoadImage className={cx('CreatePizzaCard__picture')} alt={name} src={imgSrc}/>
          {isMovieBorder && <PromotionMovieBorderDecor srcView={'4'} className={cx('CreatePizzaCard__PromoBorder')}/>}
        </div>
        <span className={cx('CreatePizzaCard__heading')}>{name}</span>
        <div className={cx('CreatePizzaCard__note')}>
          {intl.formatMessage({id: 'constructor.slogan'})}
        </div>
        <Button
          className={cx('CreatePizzaCard__button')}
          to={
            id === 15 ? getRoute(paths.constructorAliasNotId) : getRoute(paths.constructorAlias, id)
          }
          secondary
        >
          {intl.formatMessage({id: 'button.createPizza'})}
        </Button>
      </div>
    </div>
  );
}

CreatePizzaCard.defaultProps = {
  className: '',
  imgSrc: cover,
};

CreatePizzaCard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
};
